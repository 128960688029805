<template>
  <div class="order-review-wrapper flex">
    <div class="search-content searchBox">
      <search v-model="filter" @search="search" @reset="resetSearch"></search>
    </div>
    <div class="table-content inline-flex contentBox">
      <el-table :data="tableData" class="contentTable" size="medium" row-key="id" :tree-props="{children:'child',hasChildren:'hasChildren'}">
        <el-table-column prop="orderNo" label="工单号"></el-table-column>
        <el-table-column label="报修区域">
          <template slot-scope="{row}">
            {{row.repairArea.areaName}}
          </template>
        </el-table-column>
        <el-table-column label="报修人员">
          <template slot-scope="{row}">
            {{ row.createBySysUserName && row.createBySysUserName.username}}
          </template>
        </el-table-column>
        <el-table-column prop="" label="联系电话">
          <template slot-scope="{row}">
            {{row.createBySysUserName && row.createBySysUserName.mobile}}
          </template>
        </el-table-column>
        <el-table-column prop="typeName" label="故障类型">
          <template slot-scope="{row}">
            {{row.repairType.typeName}}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="维修费用">
          <template slot-scope="{row}">
            {{row.price?row.price/100:''}}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="报修时间"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="{row}">
             <span class="property-status" :class="[getStatus(row.status,'className')]" >
              {{row.statusName}}</span>
          </template>
        </el-table-column>
        <el-table-column  label="操作">
          <template  slot-scope="{row}">
            <span class="mgr10 blue" v-if="row.status===1" @click="showDetail(row.id,row.status)">审核</span>
            <span class="mgr10 blue" @click="showDetail(row.id,row.status)">查看</span>
          </template>
        </el-table-column>
      </el-table>
      <reconova-pagination @paginationChange="paginationChange" :page-no="pageIndex" :page-size="pageSize" :total="total"></reconova-pagination>
    </div>
    <div class="dialog-wrapper">
    </div>
  </div>
</template>

<script>
import ReconovaPagination from "@/components/reconova/reconovaPagination";
import pagination from "@/mixins/pagination";
import Search from "../components/search";
export default {
  name: "property-repair-review-list",
  components: {
    Search,
    ReconovaPagination
  },
  mixins:[pagination],
  data(){
    return {
      filter:{
        orderNo:null,
        submitStartTime:null,
        submitEndTime:null
      },
      editData:{},
      tableData:[],
      passStatus:[3,4,5,6,8,9,10,11,12],
      statusMap:null
    }
  },
  mounted(){
    this.search();
    this.statusMap = new Map([
      [1,['to-be-reviewed','待审核']],
      [2,['rejected','驳回']],
      [this.passStatus,['passed','通过']],
      [13,['done','已完成']],
      [7,["terminated",'已终止']],
    ])
  },
  methods:{
    // 查询
    search(pageSize=10,pageIndex=1){
      let requestParam = this.getParams(pageSize,pageIndex);
      this.$api.property.getOrderReviewList(requestParam).then(response=>{
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.pageIndex = response.data.pageNum;
        this.pageSize = response.data.pageSize;
      })
    },

    /**
     * @param status  -- 状态
     * @param prop  -- className/statusName
     */
    getStatus(status,prop){
      if(this.passStatus.includes(status)){
        return prop==='className'?this.statusMap.get(this.passStatus)[0]:this.statusMap.get(this.passStatus)[1]
      }else{
        return prop==='className'?this.statusMap.get(status)[0]:this.statusMap.get(status)[1]
      }
    },

    showDetail(id,status){
      this.$router.push(`/checkModule/property-service/property-repair-review/detail/${id}/${status}`)
    }
  }
}
</script>

<style scoped>
.property-status.to-be-reviewed{
  background: #5D68FD;
}
.property-status.passed{
  background: #7f7ffe;
}
.property-status.done{
  background: #6eb405;
}
.property-status.terminated{
  background: #d7001c;
}
.property-status.rejected{
  background: #FF6490;
}
.range-input{
  width: 400px;
}
.el-table{
  flex: 1;
  overflow-y: auto;
}
</style>