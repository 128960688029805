
<template>
  <div>
    <property-order-detail :id="id"></property-order-detail>
    <div class="occupy-ele"></div>
    <div class="fixed-bottom-btn">
      <el-row>
        <el-button class="btn-default" size="medium" @click="back">返回</el-button>
        <template v-if="status==1">
          <el-button type="danger" class="mgl10" size="medium" @click="reject">驳回</el-button>
          <el-button  type="primary" class="mgl10" size="medium" @click="pass">通过</el-button>
        </template>
      </el-row>
    </div>

    <el-dialog class="dialog-wrapper" :title="tag===1?'驳回':'通过'" :visible.sync="showDialog" width="560px">
      <el-form :model="operateObj" class="operateForm" :rules="tag===1?formRules:{}"  ref="operateForm">
        <el-form-item label="理由" prop="reason">
          <el-input type="textarea" rows="3" v-model="operateObj.reason" maxlength="80" class="form-group_input"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog=false" size="medium">取消</el-button>
        <el-button type="primary" @click="submit" size="medium">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PropertyOrderDetail from "../../../propertyModule/components/property-order-detail";
export default {
  name: "property-repair-review-detail",
  components: {PropertyOrderDetail},
  data(){
    return {
      showDialog:false,
      tag:null,
      operateObj:{},
      formRules:{
        reason:this.$formValidator.empty("驳回理由")
      },
      id:this.$route.params.id,
      status:this.$route.params.status
    }
  },
  methods:{
    back(){
      this.$router.replace('/checkModule/property-service/property-repair-review')
    },
    reject(){
      this.showDialog=true;
      this.tag=1;
      this.operateObj={};
      this.$nextTick(()=>{
        this.$refs.operateForm.clearValidate();
      })
    },
    pass(){
      this.showDialog=true;
      this.operateObj={};
      this.tag=2;
      this.$nextTick(()=>{
        this.$refs.operateForm.clearValidate();
      })
    },
    submit(){
      this.$refs.operateForm.validate((valid)=>{
        if(valid){
          const param = {
            id:parseInt(this.id),
            reason:this.operateObj.reason
          }
          let hander = this.tag==1?this.$api.property.rejectPropertyOrder(param):this.$api.property.passPropertyOrder(param)
          hander.then(res=>{
            if(res.code==200){
              this.$message.success('操作成功');
              this.back()
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>